import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import React from "react";
//import DoneIcon from '@mui/icons-material/DoneOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import {blue} from "@mui/material/colors/";
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@mui/material';

function App() {
  const {registrationId} = useParams();
  //const {signature} = useParams();
  console.log(useParams());
  const [res,setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [isLoading,setIsLoading] = useState(false);

  const CurrentDate = () => {
    const now = new Date();
    return now.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  const currentDate = CurrentDate();

  //  const color=green[500];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const API_URL = `${process.env.REACT_APP_BACKEND_URL}/services/pendingregistrationsuccess`;
        const url = window.location.href;
        const urlObj = new URL(url);

        const signature = encodeURIComponent(urlObj.searchParams.get('signature'));

        const params = { registrationId, signature };
        const queryString = new URLSearchParams(params).toString();

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'plain/text');

        const response = await fetch(`${API_URL}?${queryString}`, {
          method: 'POST',
          headers: myHeaders
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setResponse(data);
        setError(null);
        console.log(data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setError(error.message);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [registrationId]);

  return (
    <div >
      {res&& <div className="SuccessPage"
      style={{ width: "100%", display: "flex", justifyContent: "flex-end" ,  padding: "15px"}}>
        <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
          <Paper>{res.pendingPayment&&<span>Your payment is not complete, until it has been approved by the OMC staff.</span>}
          <br/>An email was sent to <b>{res.email}</b>.
          <br/>Pease check you spam/junk folder also.<br />Your payment is still pending:<br/>
          <p><b>Registration Id: </b> {res.registrationId}</p>
          <p><b>First Name: </b> {res.firstName}</p>
            <p><b>Last Name: </b>{res.lastName}</p>
            <p><b>Country: </b>{res.country}</p>
            <p><b>Total Fee: </b>{res.totalFee}€</p>
            <p><b>Attendance Type: </b>{res.section}</p>
        {
            <p><b>General Categories: </b>{res.testNames}</p>}
            <p><b>Please save this information for future reference of your registration.</b></p>


            </Paper>

        </div>}
        {isLoading &&
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                      <CircularProgress color="secondary" />
        <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
        </div>
        }
        {!isLoading && error && (
       <Alert severity="error" sx={{ marginBottom: 2 }}>
         {error}
       </Alert>
     )}
    </div>
  );
}
export default App;
