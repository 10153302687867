import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import React from "react";
//import DoneIcon from '@mui/icons-material/DoneOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import {blue} from "@mui/material/colors/";
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@mui/material';


function App() {
  const {sessionid} = useParams();
  const [res,setResponse] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //  const color=green[500];

useEffect(() => {
setIsLoading(true);


const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/registrationsuccess";
//  console.log(sessionid); // ▶ URLSearchParams {}
  //let data;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'plain/text');

  const params={sessionid:sessionid};
  const queryString = new URLSearchParams(params).toString();
  console.log(queryString);

  fetch(API_URL+"?"+queryString,{method:'POST'})
    .then(response => response.json())
    .then(data =>
      {
        setResponse(data);
    //    setIsLoading(false);
        //console.log(data);
      })
    .catch(err=>{
      console.log("ERROR reg success",err);
    setError("Something went wrong. Please contact OMC office.",err);
    //setIsLoading(false);
  })
    .finally(setIsLoading(false))

},[]);

  return (
    <div >
    {isLoading &&
      <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px'
    }}>

                  <CircularProgress color="secondary" />
    <span>Loading...please wait</span>
    </div>
    }
      {res&& <div className="SuccessPage"
      style={{ width: "100%", display: "flex", justifyContent: "flex-end" ,  padding: "15px"}}>
        <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
          <Paper>Successfull Payment. An email was sent to <b>{res.email}</b>.<br/>Please check you spam/junk folder also.<br />The details of your registration are:<br/>
          <p><b>Participating in Section: </b>{res.section}</p>
          <p><b>Unique Identification Number:</b>{res.uid}</p>
          <p><b>First Name: </b> {res.firstName}</p>
            <p><b>Last Name: </b>{res.lastName}</p>
            <p><b>Total Fee: </b>{res.totalFee}€</p>
{
            <p><b>Tests: </b>{res.testNames.toString()}</p>}
            <p><b>Please save this information for future reference of your registration.</b></p>
            <p><b>Store your Unique Identification Number to use for future competitions.</b></p>



            </Paper>
        </div>}
        {error && (
  <Alert severity="error" onClose={() => setError(null)}>
    <AlertTitle>Error</AlertTitle>
    {error}
  </Alert>
)}
    </div>
  );
}
export default App;
